import React, { useState, useEffect } from "react";
import Title from "../components/Titles/Titles.js";
import "../css/events.css";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer/footer.js";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import LoadingComponent from "../components/LoadingComponent/LoadingComponent.js";
import { Helmet } from 'react-helmet';

const EventCard = ({ event }) => {
  const [t, i18next] = useTranslation("global");
  const renderContentByType = (tipo) => {
    const info = event.evento_informacion.find((info) => info.tipo === tipo);
    if (info) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: info.contenido,
          }}
        />
      );
    }
    return null;
  };

  return (
    <div className="eventcardDIV">
      <div className="eventCardContainer">
        <img
          className="imgEventsAPI"
          src={event.link_imagen}
          alt="Event Image"
        />
        <div className="eventCardData">
          <h2>{renderContentByType("nombre")}</h2>
          <p>{renderContentByType("descripcion_corta")}</p>
          <Popup
            trigger={<button className="buttonShow">{t("events.info")}</button>}
            modal
            nested
          >
            {(close) => (
              <div className="modal">
                <button className="close" onClick={close}>
                  &times;
                </button>
                <div className="header">{renderContentByType("nombre")}</div>
                <div className="content">
                  <div className="Parent">
                    <div className="child1">
                      <img
                        className="imgEventsAPIFull"
                        src={event.link_imagen}
                        alt="Event Image"
                      />
                    </div>
                    <div className="child2 justifyText">
                      <p>{renderContentByType("descripcion_larga")}</p>
                      <p>🕥 {t("events.horai")} {event.hora_inicio}Z</p>
                      <p>🕧 {t("events.horaf")} {event.hora_final}Z</p>
                    </div>
                  </div>
                </div>
                <div className="actions">
                  <a> </a>
                </div>
              </div>
            )}
          </Popup>
        </div>
      </div>
    </div>
  );
};

export default function Home() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [t, i18next] = useTranslation("global");

  useEffect(() => {
    var myHeaders1 = new Headers();
    myHeaders1.append("X-API-Key", process.env.REACT_APP_API_KEY);

    var requestOptions1 = {
      method: "GET",
      headers: myHeaders1,
      redirect: "follow",
    };

    fetch("https://dashboard.vatsimspain.es/api/cpts", requestOptions1)
      .then((response) => response.json())
      .then((data) => {
        setEvents(data); // Assuming data is an array of events
        setLoading(false);
      })
      .catch((error) => console.log("error", error));
  }, []);

  return (
    <div>
      <Helmet>
        <title>VATSIM Spain | CPTs</title>
      </Helmet>
      <Title name='CPTs' />
      <div className="flexAlign">
        <div className="event-grid">
          {loading ? (
            <LoadingComponent />
          ) : events.length === 0 ? (
            <div className="alignData">
              <div className="alertData">
                <p className="alertText">
                  <i className="fa-solid fa-circle-exclamation"></i> {t("data.cpt")}
                </p>
              </div>
            </div>
          ) : (
            events.map((event, index) => (
              <EventCard key={index} event={event} />
            ))
          )}
        </div>
      </div>
    </div>
  );
}
