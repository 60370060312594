// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../css/var.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parentGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: 10px 10px;
  margin-top:50px;
}

.div1G, .div2G{
    background-color: var(--background);
    color: var(--text-primary);
    padding:20px;
    border-radius: 7px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


.div1G {
  grid-area: 1 / 1 / 2 / 2;
}
.div2G {
  grid-area: 1 / 2 / 2 / 3;

}


@media (max-width: 768px) {
    .parentGrid{
        display:block;
        width:100%;
    }  
    .div1G, .div2G{
      margin-top: 30px;
      background-color: var(--background);
      color: var(--text-primary);
      padding:20px;
      border-radius: 7px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  .div1G{
    margin-top: 180px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/DataGrid/dataGrid.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,uBAAuB;EACvB,cAAc;EACd,eAAe;AACjB;;AAEA;IACI,mCAAmC;IACnC,0BAA0B;IAC1B,YAAY;IACZ,kBAAkB;IAClB,iDAAiD;AACrD;;;AAGA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;;AAE1B;;;AAGA;IACI;QACI,aAAa;QACb,UAAU;IACd;IACA;MACE,gBAAgB;MAChB,mCAAmC;MACnC,0BAA0B;MAC1B,YAAY;MACZ,kBAAkB;MAClB,iDAAiD;EACrD;;EAEA;IACE,iBAAiB;EACnB;AACF","sourcesContent":["@import url(../../css/var.css);\n\n.parentGrid {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-template-rows: 1fr;\n  gap: 10px 10px;\n  margin-top:50px;\n}\n\n.div1G, .div2G{\n    background-color: var(--background);\n    color: var(--text-primary);\n    padding:20px;\n    border-radius: 7px;\n    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;\n}\n\n\n.div1G {\n  grid-area: 1 / 1 / 2 / 2;\n}\n.div2G {\n  grid-area: 1 / 2 / 2 / 3;\n\n}\n\n\n@media (max-width: 768px) {\n    .parentGrid{\n        display:block;\n        width:100%;\n    }  \n    .div1G, .div2G{\n      margin-top: 30px;\n      background-color: var(--background);\n      color: var(--text-primary);\n      padding:20px;\n      border-radius: 7px;\n      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;\n  }\n\n  .div1G{\n    margin-top: 180px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
