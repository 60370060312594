import React from 'react';
import './button.css';

const Button1 = (props) => {
  const handleClick = () => {
    window.open(props.href, props.type);
  };

  return (
    <div className='botoncito'>
      <button onClick={handleClick}>{props.text}</button>
    </div>
  );
};

export default Button1;
