// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../css/var.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contenedor{
  text-align: center;
}
.controllerText{
    font-size: 20px;
}

.alignCenter{
  text-align: center;
}

table{
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(255,255,255,0.1);
  background: rgba( 255, 255, 255, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 4px );
  -webkit-backdrop-filter: blur( 4px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  font-family:'Poppins', sans-serif;

}

 tr:nth-child(even) {
    background-color: var(--background1);
  }

  tr:nth-child(odd) {
    background-color: var(--background);
  }

.hoverText:hover{
    cursor:cursor;
}

table {
    width: 100%;
    max-width: 100%; 
    border-collapse: collapse;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    overflow: hidden;
  }
  
  th,
  td {
    padding: 10px;
    border: 1px solid transparent;
  }
  
  th {
    background-color: #f2f2f2;
    text-align: center;
    background-color: #c00202;
    color: white;
  }

  @media (max-width: 768px) {
   .contenedor{
    margin-left: 25px;
   }
  }`, "",{"version":3,"sources":["webpack://./src/components/ATCOnline/ATC.css"],"names":[],"mappings":"AAGA;EACE,kBAAkB;AACpB;AACA;IACI,eAAe;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,uCAAuC;EACvC,uCAAuC;EACvC,kDAAkD;EAClD,4BAA4B;EAC5B,oCAAoC;EACpC,mBAAmB;EACnB,6CAA6C;EAC7C,iCAAiC;;AAEnC;;CAEC;IACG,oCAAoC;EACtC;;EAEA;IACE,mCAAmC;EACrC;;AAEF;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,yBAAyB;IACzB,wCAAwC;IACxC,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;;IAEE,aAAa;IACb,6BAA6B;EAC/B;;EAEA;IACE,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;EACd;;EAEA;GACC;IACC,iBAAiB;GAClB;EACD","sourcesContent":["@import url(../../css/var.css);\n\n\n.contenedor{\n  text-align: center;\n}\n.controllerText{\n    font-size: 20px;\n}\n\n.alignCenter{\n  text-align: center;\n}\n\ntable{\n  text-align: center;\n  margin-left: auto;\n  margin-right: auto;\n  background-color: rgba(255,255,255,0.1);\n  background: rgba( 255, 255, 255, 0.25 );\n  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );\n  backdrop-filter: blur( 4px );\n  -webkit-backdrop-filter: blur( 4px );\n  border-radius: 10px;\n  border: 1px solid rgba( 255, 255, 255, 0.18 );\n  font-family:'Poppins', sans-serif;\n\n}\n\n tr:nth-child(even) {\n    background-color: var(--background1);\n  }\n\n  tr:nth-child(odd) {\n    background-color: var(--background);\n  }\n\n.hoverText:hover{\n    cursor:cursor;\n}\n\ntable {\n    width: 100%;\n    max-width: 100%; \n    border-collapse: collapse;\n    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.3);\n    border-radius: 15px;\n    overflow: hidden;\n  }\n  \n  th,\n  td {\n    padding: 10px;\n    border: 1px solid transparent;\n  }\n  \n  th {\n    background-color: #f2f2f2;\n    text-align: center;\n    background-color: #c00202;\n    color: white;\n  }\n\n  @media (max-width: 768px) {\n   .contenedor{\n    margin-left: 25px;\n   }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
