// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../css/var.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&display=swap);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stripe{
    height: 27vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.stripeText{
    text-align: center;
    vertical-align: middle;
    line-height: 35vh;
    font-size: 100px;
    color: var(--stripe);
    text-align: center;
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    
}

@media (max-width: 768px) {

    .stripe{
        height: 15vh;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 0.7vh;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    
    .stripeText{
        text-align: center;
        vertical-align: middle;
        line-height: 15vh;
        font-size: 40px;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
    }
    
  }

`, "",{"version":3,"sources":["webpack://./src/components/Titles/Titles.css"],"names":[],"mappings":"AAIA;IACI,YAAY;IACZ,WAAW;IACX,4BAA4B;IAC5B,sBAAsB;IACtB,WAAW;IACX,4BAA4B;IAC5B,2BAA2B;IAC3B,4BAA4B;IAC5B,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,iBAAiB;IACjB,gBAAgB;IAChB,oBAAoB;IACpB,kBAAkB;IAClB,kCAAkC;IAClC,yBAAyB;;AAE7B;;AAEA;;IAEI;QACI,YAAY;QACZ,WAAW;QACX,4BAA4B;QAC5B,sBAAsB;QACtB,iBAAiB;QACjB,4BAA4B;QAC5B,2BAA2B;QAC3B,4BAA4B;QAC5B,sBAAsB;IAC1B;;IAEA;QACI,kBAAkB;QAClB,sBAAsB;QACtB,iBAAiB;QACjB,eAAe;QACf,kBAAkB;QAClB,qCAAqC;IACzC;;EAEF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&display=swap');\n\n@import url(../../css/var.css);\n\n.stripe{\n    height: 27vh;\n    width: 100%;\n    background-repeat: no-repeat;\n    background-size: cover;\n    margin: 0px;\n    background-attachment: fixed;\n    background-position: center;\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n\n.stripeText{\n    text-align: center;\n    vertical-align: middle;\n    line-height: 35vh;\n    font-size: 100px;\n    color: var(--stripe);\n    text-align: center;\n    font-family: 'Poppins', sans-serif;\n    text-transform: uppercase;\n    \n}\n\n@media (max-width: 768px) {\n\n    .stripe{\n        height: 15vh;\n        width: 100%;\n        background-repeat: no-repeat;\n        background-size: cover;\n        margin-top: 0.7vh;\n        background-attachment: fixed;\n        background-position: center;\n        background-repeat: no-repeat;\n        background-size: cover;\n    }\n    \n    .stripeText{\n        text-align: center;\n        vertical-align: middle;\n        line-height: 15vh;\n        font-size: 40px;\n        text-align: center;\n        font-family: 'Montserrat', sans-serif;\n    }\n    \n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
