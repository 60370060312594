// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./var.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    height: 500px;
  }

.floatRight{
    float: right;
    height: 120px;
}

.floatLeft{
    float: left;
    height: 120px;
}

.bodyPilots{
  color: var(--text-primary);
}

.bodyPilots a:link{
  color: #A30000;
}

.bodyPilots:visited{
  color: #A30000;
}



  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }

.bodyPilots {
  margin-left: 25vh;
  margin-right: 25vh;
}


@media screen and (max-width: 768px) {


.floatRight{
    float: right;
    height: auto;
}

.floatLeft{
    float: left;
    height: auto;
}

  .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }

.bodyPilots {
  display:block;
  margin-left: 2vh;
  margin-right: 2vh;
}
  
}


`, "",{"version":3,"sources":["webpack://./src/css/pilots.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,2BAA2B;IAC3B,aAAa;EACf;;AAEF;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;;;EAIE;IACE,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,kBAAkB;EACpB;;AAEF;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;;AAGA;;;AAGA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;EAEE;IACE,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,kBAAkB;EACpB;;AAEF;EACE,aAAa;EACb,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA","sourcesContent":["@import url(../css/var.css);\n\n.each-slide-effect > div {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-size: cover;\n    background-position: center;\n    height: 500px;\n  }\n\n.floatRight{\n    float: right;\n    height: 120px;\n}\n\n.floatLeft{\n    float: left;\n    height: 120px;\n}\n\n.bodyPilots{\n  color: var(--text-primary);\n}\n\n.bodyPilots a:link{\n  color: #A30000;\n}\n\n.bodyPilots:visited{\n  color: #A30000;\n}\n\n\n\n  .each-slide-effect span {\n    padding: 20px;\n    font-size: 20px;\n    background: #efefef;\n    text-align: center;\n  }\n\n.bodyPilots {\n  margin-left: 25vh;\n  margin-right: 25vh;\n}\n\n\n@media screen and (max-width: 768px) {\n\n\n.floatRight{\n    float: right;\n    height: auto;\n}\n\n.floatLeft{\n    float: left;\n    height: auto;\n}\n\n  .each-slide-effect span {\n    padding: 20px;\n    font-size: 20px;\n    background: #efefef;\n    text-align: center;\n  }\n\n.bodyPilots {\n  display:block;\n  margin-left: 2vh;\n  margin-right: 2vh;\n}\n  \n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
