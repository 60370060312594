import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Title from "../components/Titles/Titles.js";
import "../css/controllers.css";
import LoadingComponent from "../components/LoadingComponent/LoadingComponent.js";
import { Table, Input } from "antd";
import { Helmet } from 'react-helmet';

const ControllersTab = () => {
  const [waiters, setWaiters] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation("global");
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://dashboard.vatsimspain.es/api/formacion/listaEsperaFormacion",
          {
            method: "GET",
            headers: {
              "X-API-Key": process.env.REACT_APP_API_KEY,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        setWaiters(data);
        setLoading(false);
        setFilteredData(data);
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = data.filter(
      (item) =>
        String(item.nombre_controlador)
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        String(item.rango_controlador)
          .toLowerCase()
          .includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: "numero_orden",
      key: "numero_orden",
    },
    {
      title: t("controllers.ciduser"),
      dataIndex: "nombre_controlador",
      key: "nombre_controlador",
    },
    {
      title: t("controllers.rating"),
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: t("controllers.added"),
      dataIndex: "fecha_solicitud",
      key: "fecha_solicitud",
    },
  ];

  return (
    <div>
      <Helmet>
        <title>VATSIM Spain | {t("controllers.listaFormacion1")}</title>
      </Helmet>
      <Title name={t("controllers.listaFormacion1")} />
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="tableCont">
        <div className="table">
        <Input.Search
              placeholder={t("controllers.search")}
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ marginBottom: 16 }}
            />
            <Table dataSource={filteredData} columns={columns} />
        </div>
        </div>
      )}
    </div>
  );
};

export default ControllersTab;
