import React from 'react';
import './button2.css';

const Button = (props) => {
  const hrefValue = props.href;
  const scrollToAnchor = () => {
    const anchorElement = document.getElementById(props.anchor);
    if (anchorElement) {
      window.scrollTo({
        top: anchorElement.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className='botoncito2'>
      <button  onClick={scrollToAnchor || hrefValue }>{props.text}</button>
    </div>
  );
};

export default Button;
