import React from 'react';

var userAgent = navigator.userAgent.toLowerCase();
var supported = /(chrome|firefox)/;

if (supported.test(userAgent.toLowerCase())) {
  var dark = [
    'padding: 20px 5px 16px',
    'border-top-left-radius: 10px',
    'border-bottom-left-radius: 10px',
    'background-color: #A30000',
    'color: #f5f5f5',
    'background-image: url("https://unaigonzalez.es/favicon.ico")',
    'background-position: 10px 9px',
    'background-repeat: no-repeat',
    'background-size: 26px 30px',
  ].join(';');

  var gold = [
    'padding: 20px 5px 16px',
    'background-color: #f5f5f5',
    'border-top-right-radius: 10px',
    'border-bottom-right-radius: 10px',
    'color: #ffffff'
  ].join(';');

  var spacer = ['background-color: transparent'].join(';');

  var msg =
    '\n\n %c Crafted with ♥︎ by Unai González %c https://unaigonzalez.es %c \n\n\n';

  console.log(msg, dark, gold, spacer);
  console.log('What are you looking here? Close this window! ;)')
} else if (window.console) {
  console.log('Crafted with love by Unai González - https://unaigonzalez.es ');
  console.log('What are you looking here? Close this window! ;)')
}

export default function CraftedWithLove() {
  return <div></div>;
}
