// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../css/var.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.socialMediaIcon{
    display: inline;
    margin: 1vh;
    filter: var(--invert);
}

.socialMediaIconIMG{
    height: 30px;
    transition: 0.1s;
}

.socialMediaIconIMG:hover{
    height: 30px;
    transform: scale(1.1);
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/SocialMediaIcon/socialMediaIcon.css"],"names":[],"mappings":";AAGA;IACI,eAAe;IACf,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,eAAe;AACnB","sourcesContent":["\n@import url(../../css/var.css);\n\n.socialMediaIcon{\n    display: inline;\n    margin: 1vh;\n    filter: var(--invert);\n}\n\n.socialMediaIconIMG{\n    height: 30px;\n    transition: 0.1s;\n}\n\n.socialMediaIconIMG:hover{\n    height: 30px;\n    transform: scale(1.1);\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
