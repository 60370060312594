import React from "react";
import './dataGrid.css'
import ATCOnline from '../ATCOnline/ATC.js'
import Bookings from '../BookingsATC/BookingsATC.js'

export default function dataGrid() {
  return (
    <div>
      <div className="parentGrid">
        <div className="div1G"><ATCOnline /> </div>
        <div className="div2G"><Bookings /></div>
      </div>
    </div>
  );
}