import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./buttonLanguage.css";
import ES from "../../img/lang/es.png";
import EN from "../../img/lang/en.png";
import Cookies from "js-cookie";

const LanguageToggle = () => {
  const { t, i18n } = useTranslation("global");

  useEffect(() => {
    const savedLanguage = Cookies.get("selectedLanguage");
    if (savedLanguage && ["en", "es"].includes(savedLanguage)) {
      i18n.changeLanguage(savedLanguage);
    }

    var languageNow = savedLanguage;
  }, [i18n]);

  const handleLanguageChange = (newLanguage) => {
    i18n.changeLanguage(newLanguage);
    Cookies.set("selectedLanguage", newLanguage, { expires: 365 });
  };

  return (
    <div className="language-dropdown">
      <img
        className="imgIcon"
        src={i18n.language === "en" ? EN : ES}
        alt={i18n.language === "en" ? "English" : "Español"}
      />
      <select
        value={i18n.language}
        onChange={(e) => handleLanguageChange(e.target.value)}
      >
        <option value="en">EN</option>
        <option value="es">ES</option>
      </select>
    </div>
  );
};

export default LanguageToggle;

export var savedLanguage;
