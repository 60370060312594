import React, { useEffect, useState } from "react";
import "../css/staff.css";
import Title from "../components/Titles/Titles.js";
import i18next from "i18next";
import LoadingComponent from "../components/LoadingComponent/LoadingComponent.js";
import { Helmet } from 'react-helmet';

const StaffTab = () => {
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const savedLanguage = i18next.language;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://dashboard.vatsimspain.es/api/staff",
          {
            method: "GET",
            headers: {
              "X-API-Key": process.env.REACT_APP_API_KEY,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        setDepartments(data);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>VATSIM Spain | Staff</title>
      </Helmet>
        <div>
                <Title name="STAFF"/>
                {loading ? (
            <LoadingComponent />
          ) : (
          <div className="containerStaff">
            {departments.map((department) => (
              <div key={department.id} className="boxStaff">
                {savedLanguage === "es" ? (
                  <a className="nameClass">{department.nombre}</a>
                ) : (
                  <a className="nameClass">{department.name}</a>
                )}
                <a className="classEmail" href={`mailto:${department.email}`}>
                  {department.email}
                </a>
                <div className="staff">
                  {department.departamento_posiciones.map((staffPosition) => (
                    <div key={staffPosition.id} className="staffBox">
                      {savedLanguage === "es" ? (
                        <a className="staffCharge">{staffPosition.nombre} - </a>
                      ) : (
                        <a className="staffCharge">{staffPosition.name} - </a>
                      )}
                      <a className="staffName">
                        {staffPosition.nombre_staff}{" "}
                        {staffPosition.nombre_staff == null ? "VACANT" : null}
                      </a>
                      <a
                        className="staffEmail"
                        href={`mailto:${staffPosition.email_staff}`}
                      >
                        {staffPosition.email_staff}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          )}
        </div>
    </div>
  );
};


export default StaffTab;
