// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.botoncito2 button{
    font-family: "Poppins", sans-serif;
    border: 2px solid white;
    color: white;
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
    background-color: transparent;
    transition: 0.1s;
}

.botoncito2 button:hover{
    transform: scale(1.1);
    cursor: pointer;

}`, "",{"version":3,"sources":["webpack://./src/components/Button/button2.css"],"names":[],"mappings":"AAKA;IACI,kCAAkC;IAClC,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,eAAe;;AAEnB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap\");\n\n\n\n\n.botoncito2 button{\n    font-family: \"Poppins\", sans-serif;\n    border: 2px solid white;\n    color: white;\n    padding: 10px;\n    border-radius: 10px;\n    font-size: 15px;\n    background-color: transparent;\n    transition: 0.1s;\n}\n\n.botoncito2 button:hover{\n    transform: scale(1.1);\n    cursor: pointer;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
