import React, { useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import Title from "../components/Titles/Titles.js";
import "../css/controllers.css";
import LoadingComponent from "../components/LoadingComponent/LoadingComponent.js";
import { Table, Input } from "antd";
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';

const VisitorTab= () => {
  const [data, setData] = useState([]);
  const [t, i18next] = useTranslation("global");
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://dashboard.vatsimspain.es/api/controladores/visitantes",
          {
            method: "GET",
            headers: {
              "X-API-Key": process.env.REACT_APP_API_KEY,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const controllerData = await response.json();

        setData(controllerData);
        setFilteredData(controllerData);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = data.filter(
      (item) =>
        String(item.nombre_controlador)
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        String(item.rango_controlador)
          .toLowerCase()
          .includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const columns = [
    {
      title: t("controllers.ciduser"),
      dataIndex: "nombre_controlador",
      key: "nombre_controlador",
    },
    {
      title: t("controllers.rating1"),
      dataIndex: "rango_controlador",
      key: "rango_controlador",
    },
    {
      title: 'Tier 1',
      dataIndex: "posiciones_habilitadas_tier_1",
      key: "posiciones_habilitadas_tier_1",
      render: text => {
        const dom = new DOMParser().parseFromString(text, 'text/html');
        const items = Array.from(dom.querySelectorAll('li')).map(li => li.textContent);
        return items.join(' - ');
      }
    },
    {
      title: 'Tier 2',
      dataIndex: "posiciones_habilitadas_tier_2",
      key: "posiciones_habilitadas_tier_2",
      render: text => {
        const dom = new DOMParser().parseFromString(text, 'text/html');
        const items = Array.from(dom.querySelectorAll('li')).map(li => li.textContent);
        return items.join(' - ');
      }
    },
  ];

  return (
    <div>
      <Helmet>
        <title>VATSIM Spain | {t("controllers.visitor")}</title>
      </Helmet>
      <Title name={t("controllers.visitor")} />
      {loading ? (
        <LoadingComponent />
      ) : (
        <div className="cantidadATC">
        <h3><i className="fa-solid fa-user"></i> {filteredData.length}</h3>
        <div className="tableCont">
        <div className="table">
          <Input.Search
              placeholder={t("controllers.search")}
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ marginBottom: 16 }}
            />
            <Table dataSource={filteredData} columns={columns} />
        </div>
        </div>
        </div>
      )}
    </div>
  );
};




export default VisitorTab;
