import React from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

export default function Cookies() {
  const [t, i18next] = useTranslation("global");
  
  return (
    <CookieConsent
      location="bottom"
      buttonText={t("cookies.ok")}
      cookieName="VATSIMSpainCookie"
      style={{ background: "#191919" }}
      buttonStyle={{
        color: "#FFFFFF",
        fontSize: "13px",
        background: "#C00202",
        borderRadius: "5px",
        fontFamily: "Poppins",
      }}
      expires={150}
    >
      {t("cookies.text")}
    </CookieConsent>
  );
}
