import * as React from 'react';
import { DarkModeSwitch } from 'react-toggle-dark-mode';

// @ts-ignore
import { useTheme } from './useTheme.ts';

export default function Switch() {
  const [theme, toggleTheme] = useTheme('light');

  return (
    <div>
      <DarkModeSwitch
        style={{}}
        checked={theme === 'dark'} // Check if the theme is 'dark'
        onChange={toggleTheme} // Use toggleTheme to switch between 'dark' and 'light'
        size={20}
      />
    </div>
  );
}
