// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../css/var.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.botoncito button{
    font-family: "Poppins", sans-serif;
    border: 2px solid var(--text-primary);
    color: var(--text-primary);
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
    background-color: transparent;
    transition: 0.1s;
}

.botoncito button:hover{
    transform: scale(1.1);
    cursor: pointer;

}`, "",{"version":3,"sources":["webpack://./src/components/Button/button.css"],"names":[],"mappings":"AAKA;IACI,kCAAkC;IAClC,qCAAqC;IACrC,0BAA0B;IAC1B,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,eAAe;;AAEnB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap\");\n\n@import url(../../css/var.css);\n\n\n.botoncito button{\n    font-family: \"Poppins\", sans-serif;\n    border: 2px solid var(--text-primary);\n    color: var(--text-primary);\n    padding: 10px;\n    border-radius: 10px;\n    font-size: 15px;\n    background-color: transparent;\n    transition: 0.1s;\n}\n\n.botoncito button:hover{\n    transform: scale(1.1);\n    cursor: pointer;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
