import React from 'react';
import Title from "../components/Titles/Titles.js";
import '../css/pilots.css'
import { Slide } from 'react-slideshow-image';
import { Helmet } from 'react-helmet';
import 'react-slideshow-image/dist/styles.css';
import { useTranslation } from 'react-i18next';
import img1 from '../img/slide/1.png'
import img2 from '../img/slide/2.png'
import img3 from '../img/slide/3.png'
import img4 from '../img/slide/4.png'
import img5 from '../img/slide/5.png'
import Teams from '../components/Teams/teams.js'


export default function PilotsPage() {
    const [t, i18next] = useTranslation("global");


    return (
        <div className='bodyPilots'>
           <Helmet>
            <title>VATSIM Spain | {t("pilots.title")}</title>
          </Helmet>
            <Title name={t("pilots.title")} />
            
            <Slide transitionDuration='500'>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${img1})` }}></div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${img2})` }}></div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${img3})` }}></div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${img4})` }}></div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${img5})` }}></div>
            </div>
        </Slide>

            <h2>{t("pilots.title1")}</h2>
            <h3>{t("pilots.subtitle")}</h3>
            
            <h4>{t("pilots.pasos")}</h4>
            <ol>
          <li>
            <span>1.</span>
            {t("pilots.paso1")} <br />{" "}
            <a href="https://my.vatsim.net/register">
              ‎ https://my.vatsim.net/register
            </a>
          </li>
          <li>
            <span>2.</span>
            {t("pilots.paso2")}
          </li>
          <li>
            <span>3.</span>
            {t("pilots.paso3")}
          </li>
          <li>
            <span>4.</span>
            {t("pilots.paso4")}
          </li>
          <h3>{t("pilots.volar")}</h3>
        </ol>

            <h4>{t("pilots.va")}</h4>
            <p>{t("pilots.vaText")}</p>
            <Teams/>

            <h4>{t("pilots.cartas")}</h4>
            <img className='floatLeft' src='https://assets-global.website-files.com/5b27a494dc3d312fe5c01da8/63ee3e38bcfb61e9a3cf71b9_Logo_SimBrief_Navigraph.png'></img>
            <p>{t("pilots.cartas1")}<a href="https://aip.enaire.es/AIP/">Cartas aeronáuticas</a></p>
            <p>{t("pilots.cartas2")}</p>


            <h4>{t("pilots.atc")}</h4>
            <p>{t("pilots.atcText")}</p>
            <ul>
            <li><a href="#">{t("pilots.reservas")}</a></li>
            <li><a href="/controladores">{t("pilots.atc")}</a></li>
            </ul>
            


            <h4>{t("pilots.eventos")}</h4>
            <img className='floatRight' src='https://www.airportspotting.com/wp-content/uploads/2018/01/Iberia-A320neo.png'></img>
            <p>{t("pilots.eventos1")}</p>
            <p>{t("pilots.eventos2")}</p>
            <ul>
                <li><a href="https://vatsimspain.es/eventos">{t("pilots.eventosES")}</a></li>
                <li><a href="https://vatsim.net/events/">{t("pilots.eventosGL")}</a></li>
            </ul>
            <h4>{t("pilots.piloto")}</h4>
            <p>{t("pilots.piloto2")}</p>
            <p>{t("pilots.piloto3")}</p>
            <h2>{t("pilots.piloto4")}</h2>
        </div>
    );
}
