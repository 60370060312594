import React, { useState, useEffect } from 'react';
import './LoadingComponent.css';

const LoadingScreen = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  
    document.body.classList.add('no-scroll');
  
    return () => {
      clearTimeout(timer);
      document.body.classList.remove('no-scroll');
    };
  }, []);
  

  return (
    <div className='loadingComponent'>
      <div className={`loading-screen ${isLoading ? 'show' : ''}`}>
        <div className="dots"></div>
      </div>
    </div>
  );
};

export default LoadingScreen;
