// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-scroll {
   overflow: hidden;
 }

.loadingComponent{
    display: flex;
    justify-content: center;
    margin-bottom: 40vh;
    z-index: 100000;
}

.dots {
    background-color: white;
    width: 1300px;
    width: 88px;
    height: 42.2px;
    background: radial-gradient(circle closest-side,#a30000 90%,#0000) 0%   50%,
           radial-gradient(circle closest-side,#a30000 90%,#0000) 50%  50%,
           radial-gradient(circle closest-side,#a30000 90%,#0000) 100% 50%;
    background-size: calc(100%/3) 21.1px;
    background-repeat: no-repeat;
    animation: dots-7ar3yq 1s infinite linear;
 }
 
 @keyframes dots-7ar3yq {
    20% {
       background-position: 0%   0%, 50%  50%,100%  50%;
    }
 
    40% {
       background-position: 0% 100%, 50%   0%,100%  50%;
    }
 
    60% {
       background-position: 0%  50%, 50% 100%,100%   0%;
    }
 
    80% {
       background-position: 0%  50%, 50%  50%,100% 100%;
    }
 }

 .loading-screen {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .loading-screen.show {
    opacity: 1;
  }`, "",{"version":3,"sources":["webpack://./src/components/LoadingComponent/LoadingComponent.css"],"names":[],"mappings":"AAAA;GACG,gBAAgB;CAClB;;AAED;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,WAAW;IACX,cAAc;IACd;;0EAEsE;IACtE,oCAAoC;IACpC,4BAA4B;IAC5B,yCAAyC;CAC5C;;CAEA;IACG;OACG,gDAAgD;IACnD;;IAEA;OACG,gDAAgD;IACnD;;IAEA;OACG,gDAAgD;IACnD;;IAEA;OACG,gDAAgD;IACnD;CACH;;CAEA;IACG,UAAU;IACV,oCAAoC;EACtC;;EAEA;IACE,UAAU;EACZ","sourcesContent":[".no-scroll {\n   overflow: hidden;\n }\n\n.loadingComponent{\n    display: flex;\n    justify-content: center;\n    margin-bottom: 40vh;\n    z-index: 100000;\n}\n\n.dots {\n    background-color: white;\n    width: 1300px;\n    width: 88px;\n    height: 42.2px;\n    background: radial-gradient(circle closest-side,#a30000 90%,#0000) 0%   50%,\n           radial-gradient(circle closest-side,#a30000 90%,#0000) 50%  50%,\n           radial-gradient(circle closest-side,#a30000 90%,#0000) 100% 50%;\n    background-size: calc(100%/3) 21.1px;\n    background-repeat: no-repeat;\n    animation: dots-7ar3yq 1s infinite linear;\n }\n \n @keyframes dots-7ar3yq {\n    20% {\n       background-position: 0%   0%, 50%  50%,100%  50%;\n    }\n \n    40% {\n       background-position: 0% 100%, 50%   0%,100%  50%;\n    }\n \n    60% {\n       background-position: 0%  50%, 50% 100%,100%   0%;\n    }\n \n    80% {\n       background-position: 0%  50%, 50%  50%,100% 100%;\n    }\n }\n\n .loading-screen {\n    opacity: 0;\n    transition: opacity 0.5s ease-in-out;\n  }\n  \n  .loading-screen.show {\n    opacity: 1;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
