import React from "react";
import { useTranslation } from "react-i18next";
import Title from "../components/Titles/Titles.js";
import "../css/controllers.css";
import { Table } from "antd";
import { Helmet } from 'react-helmet';


const TiersPage = () => {
  const [t, i18next] = useTranslation("global");

  const columns = [
    {
      title: t("ATC.station"),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("ATC.name"),
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: t("ATC.frequency"),
      dataIndex: "freq",
      key: "freq",
    },
  ];

  const dataTable = [
    {
      id: "LEMH_A_TWR",
      nombre: "Menorca Aproximación",
      freq: "119.655"
    },
    {
      id: "LEGE_A_TWR",
      nombre: "Girona Aproximación",
      freq: "120.905"
    },
    {
      id: "LERS_A_TWR",
      nombre: "Reus Torre",
      freq: "128.880"
    },
    {
      id: "LEAM_A_TWR",
      nombre: "Almería Aproximación",
      freq: "118.350"
    },
    {
      id: "LEGR_A_TWR",
      nombre: "Granada Torre",
      freq: "118.855"
    },
    {
      id: "LEAS_A_TWR",
      nombre: "Asturias Aproximación",
      freq: "118.150"
    },
    {
      id: "LEXJ_A_TWR",
      nombre: "Santander Torre",
      freq: "118.375"
    },
    {
      id: "LESO_A_TWR",
      nombre: "San Sebastián Torre",
      freq: "119.855"
    },
    {
      id: "LEVT_A_TWR",
      nombre: "Vitoria Torre",
      freq: "118.450"
    },
    {
      id: "LERJ_A_TWR",
      nombre: "Rioja Torre",
      freq: "118.580"
    },
    {
      id: "LEPP_A_TWR",
      nombre: "Pamplona Torre",
      freq: "118.200"
    },
    {
      id: "GEML_A_TWR",
      nombre: "Melilla",
      freq: "119.525"
    }
  ];

  return (
    <div>
      <Helmet>
        <title>VATSIM Spain | {t("controllers.title")}</title>
      </Helmet>
      <Title name="TIER 2" />
        <div className="cantidadATC">
          <h3><i className="fa-solid fa-radio"></i> {dataTable.length}</h3>
        <div className="tableCont">
          <div className="table">
            <Table dataSource={dataTable} columns={columns} />
          </div>
        </div>
        </div>
    </div>
  );
};

export default TiersPage;
