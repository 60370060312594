import React from 'react'
import '../css/notFound.css'
import { Helmet } from 'react-helmet';

export default function NotFound() {
  return (
    <div className='notFound'>
      <Helmet>
        <title>VATSIM Spain | Not Found</title>
      </Helmet>
      <p className='textError'>404</p>
      <p className='surError'>Oops! It seems you've taken a wrong turn in the virtual skies.</p>
      <p>Enjoy the Scenic Route: Sometimes, the most memorable flights are those with unexpected detours. Explore our website, and you might discover something new and exciting.</p>
      <br/>
      <a href="https://vatsimspain.es/">
    <button>Go home</button>
  </a> 
      <br/>
      <br/>
    </div>
  )
}
