// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homeContainer{
    margin:60px;
}

.custom-shape-divider-bottom-1691246619 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }
  
  .custom-shape-divider-bottom-1691246619 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 75px;
    margin-top: 10vh
  }
  
  .custom-shape-divider-bottom-1691246619 .shape-fill {
    fill: var(--background);
  }
  
  @media screen and (max-width: 768px) {
  
    .custom-shape-divider-bottom-1691246619 svg {
      margin-top:22px;
    }

    .homeContainer{
        margin:10px;
    }
    
  }`, "",{"version":3,"sources":["webpack://./src/css/home.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,WAAW;IACX,gBAAgB;IAChB,cAAc;IACd,yBAAyB;EAC3B;;EAEA;IACE,kBAAkB;IAClB,cAAc;IACd,yBAAyB;IACzB,YAAY;IACZ;EACF;;EAEA;IACE,uBAAuB;EACzB;;EAEA;;IAEE;MACE,eAAe;IACjB;;IAEA;QACI,WAAW;IACf;;EAEF","sourcesContent":[".homeContainer{\n    margin:60px;\n}\n\n.custom-shape-divider-bottom-1691246619 {\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    width: 100%;\n    overflow: hidden;\n    line-height: 0;\n    transform: rotate(180deg);\n  }\n  \n  .custom-shape-divider-bottom-1691246619 svg {\n    position: relative;\n    display: block;\n    width: calc(100% + 1.3px);\n    height: 75px;\n    margin-top: 10vh\n  }\n  \n  .custom-shape-divider-bottom-1691246619 .shape-fill {\n    fill: var(--background);\n  }\n  \n  @media screen and (max-width: 768px) {\n  \n    .custom-shape-divider-bottom-1691246619 svg {\n      margin-top:22px;\n    }\n\n    .homeContainer{\n        margin:10px;\n    }\n    \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
