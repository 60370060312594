import React, { Component } from 'react';
import './Titles.css'

function Stripe(props) {
  return (
    <div className='stripe'>
      <h3 className='stripeText'>{props.name}</h3>
    </div>
  )
}

export default Stripe;