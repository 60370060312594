import React from 'react';
import './footer.css';
import SocialMediaIcon from '../SocialMediaIcon/socialMediaIcon.js'
import { useTranslation } from 'react-i18next';

import IG from '../../img/rrss/IG.png'
import YT from '../../img/rrss/YT.png'
import TW from '../../img/rrss/TW.png'
import DS from '../../img/rrss/DS.png'



export default function Footer() {
  const [t, i18next] = useTranslation("global");

  const handleCopyrightClick = () => {
    alert("You discovered my easter egg! Made with ❤️ by Unai González (@unaiitxuu) ;)");
  };

  return (
    <div>
    <div className='feedback'>
      <p className='feedbackText'>💌 {t("footer.feedback")}<a className='mailLink' href="mailto:feedback@vatsimspain.es">feedback@vatsimspain.es</a> !</p>
    </div>
    <footer className="footer">
      <div className="footer__addr">
      <div className='logoFooter'></div>
      </div>

      <ul className="footer__nav">
        <li className="nav__item">
          <h2 className="nav__title">{t("footer.title1")}</h2>
            
          <ul className="nav__ul">
            <li>
              <a href="/eventos" target="_self" >{t("footer.events")}</a>
            </li>
            <li>
              <a href="/staff" target="_self">{t("footer.staff")}</a>
            </li>
            <li>
              <a href="/mentores" target="_self">{t("navbar.mentors")}</a>
            </li>
            <li>
              <a href="/examinadores" target="_self">{t("navbar.examiners")}</a>
            </li>
            <li>
              <a href="/teams" target="_self">Teams</a>
            </li>
          </ul>
        </li>

        <li className="nav__item">
          <h2 className="nav__title">{t("footer.title2")}</h2>

          <ul className="nav__ul">
            <li>
              <a href="/controladores" target="_self" >{t("footer.controllerRoster")}</a>
            </li>
            <li>
              <a href="/visitantes" target="_self" >{t("controllers.visitor")}</a>
            </li>
            <li>
              <a href="/solo" target="_self" >Solo Validation</a>
            </li>
            <li>
              <a href="https://biblioteca.vatsimspain.es/books/manual-de-formacion" target="_blank" >{t("footer.manual")}</a>
            </li>

          </ul>
        </li>

        <li className="nav__item">
          <h2 className="nav__title">{t("footer.title5")}</h2>

          <ul className="nav__ul">
            <li>
              <a href="/pilotos" target="_self" >{t("footer.titlePiloto")}</a>
            </li>

          </ul>
        </li>

        <li className="nav__item">
          <h2 className="nav__title">{t("footer.title3")}</h2>

          <ul className="nav__ul">
          <li>
              <a href="https://biblioteca.vatsimspain.es/books/documentacion/page/lopd-ley-de-proteccion-de-datos" target="_blank">{t("footer.data")}</a>
            </li>

            <li>
              <a href="https://biblioteca.vatsimspain.es/books/documentacion/page/reglamento-del-vacc" target="_blank">{t("footer.rules")}</a>
            </li>
          </ul>
        </li>

        <li className="nav__item">
          <h2 className="nav__title">{t("footer.title4")}</h2>

          <ul className="nav__ul">
          <li>
              <a href="https://dashboard.vatsimspain.es/" target="_blank">VATSIM Spain Dashbaord</a>
            </li>
          <li>
              <a href="https://vatglasses.uk/?lat=39.85&lon=-5.25&zoom=6" target="_blank">VATGlasses</a>
            </li>
            <li>
              <a href="https://stats.vatsim.net/dashboard" target="_blank">VATSIM Stats/Tracker</a>
            </li>

            <li>
              <a href="" target="_blank"></a>
            </li>
          </ul>
        </li>
      </ul>

      <div className="legal">
        <p>
          <a onClick={handleCopyrightClick}>© </a><a>{(new Date().getFullYear())} {t("footer.footer1")}</a>
        </p>

        <div className="legal__links">
          <SocialMediaIcon location={IG} href="https://www.instagram.com/vatsimspain/" />
          <SocialMediaIcon location={TW} href="https://twitter.com/vatsimSpain" />
          <SocialMediaIcon location={YT} href="https://www.youtube.com/@vatsimspain9466" />
          <SocialMediaIcon location={DS} href="https://dashboard.vatsimspain.es/home/perfil" />
        </div>
      </div>
    </footer>
    </div>
  );
}
