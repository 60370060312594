// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../css/var.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table1{
  background-color: var(--background);
  width: 100%;
  display:flex;
  text-align: center;
  place-content: center;
  color: var(--text-primary);
  font-family:'Poppins', sans-serif;
}

table {
  width: 100%;
}

.textAlign{
    text-align: center;
    color: var(--text-primary);}

`, "",{"version":3,"sources":["webpack://./src/components/BookingsATC/BookingsATC.css"],"names":[],"mappings":"AAIA;EACE,mCAAmC;EACnC,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,qBAAqB;EACrB,0BAA0B;EAC1B,iCAAiC;AACnC;;AAEA;EACE,WAAW;AACb;;AAEA;IACI,kBAAkB;IAClB,0BAA0B,CAAC","sourcesContent":["@import '../../css/var.css';\n\n\n\n.table1{\n  background-color: var(--background);\n  width: 100%;\n  display:flex;\n  text-align: center;\n  place-content: center;\n  color: var(--text-primary);\n  font-family:'Poppins', sans-serif;\n}\n\ntable {\n  width: 100%;\n}\n\n.textAlign{\n    text-align: center;\n    color: var(--text-primary);}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
