import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button1 from '../Button/button1'
import './BookingsATC.css';

const ControllersTab = () => {
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation("global");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_RESERVAS_URL,
          {
            method: "GET",
            headers: {
              "X-API-Key": process.env.REACT_APP_API_KEY,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        setBookings(data);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex">
      <h1 className="textAlign"><i className="fa-regular fa-calendar-days"></i> {t("bookings.bookingsTitle")}</h1>
      <div className="alignData">
        {bookings.length > 0 ? (
          <div className="table1">
            <table>
              <tbody>
                {bookings.map((item) => (
                  <tr key={item.usuario_id}>
                    <td>{item.posicion_id}</td>
                    <td>{item.hora_inicio + "Z - " + item.hora_final + "Z"}</td>
                    <td>{item.nombre_controlador}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
            <p className="alertText1">
             {t("data.noBookings")}
            </p>
        )}
      </div>        
      <br/>
      <div className='alignCenter'>
        <Button1 className="botonWho" text="VATSIM Radar 👓" href="https://vatsim-radar.com/" type="_blank"/>
      </div>
    </div>
  );
        }

export default ControllersTab;