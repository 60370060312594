// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./var.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #a30000;
  border: 0px solid #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
::-webkit-scrollbar-thumb:active {
  background: #000000;
}
::-webkit-scrollbar-track {
  background: #f5f5f5;
  border: 11px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

body{
  background-color: var(--colorfondo);
  background-image: var(--fondo) ;
  font-family: 'Poppins';
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}


hr{
  height: 1px;
  background-color: white;
  width: 100%;
}

body{
  transition: all .5s ease-in-out;

}`, "",{"version":3,"sources":["webpack://./src/css/App.css"],"names":[],"mappings":"AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,WAAW;AACb;AACA;EACE,UAAU;EACV,WAAW;AACb;AACA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,uBAAuB;AACzB;;AAEA;EACE,mCAAmC;EACnC,+BAA+B;EAC/B,sBAAsB;AACxB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,iCAAiC;AACnC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,kCAAkC;AACpC;;;AAGA;EACE,WAAW;EACX,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,+BAA+B;;AAEjC","sourcesContent":["@import url(../css/var.css);\n\nhtml {\n  scroll-behavior: smooth;\n}\n\n::-webkit-scrollbar {\n  width: 5px;\n  height: 4px;\n}\n::-webkit-scrollbar-button {\n  width: 0px;\n  height: 0px;\n}\n::-webkit-scrollbar-thumb {\n  background: #a30000;\n  border: 0px solid #ffffff;\n  border-radius: 0px;\n}\n::-webkit-scrollbar-thumb:hover {\n  background: #ffffff;\n}\n::-webkit-scrollbar-thumb:active {\n  background: #000000;\n}\n::-webkit-scrollbar-track {\n  background: #f5f5f5;\n  border: 11px none #ffffff;\n  border-radius: 0px;\n}\n::-webkit-scrollbar-track:hover {\n  background: #666666;\n}\n::-webkit-scrollbar-track:active {\n  background: #333333;\n}\n::-webkit-scrollbar-corner {\n  background: transparent;\n}\n\nbody{\n  background-color: var(--colorfondo);\n  background-image: var(--fondo) ;\n  font-family: 'Poppins';\n}\n\n.fade-enter {\n  opacity: 0;\n}\n\n.fade-enter-active {\n  opacity: 1;\n  transition: opacity 300ms ease-in;\n}\n\n.fade-exit {\n  opacity: 1;\n}\n\n.fade-exit-active {\n  opacity: 0;\n  transition: opacity 300ms ease-out;\n}\n\n\nhr{\n  height: 1px;\n  background-color: white;\n  width: 100%;\n}\n\nbody{\n  transition: all .5s ease-in-out;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
