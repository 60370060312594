import React from "react";
import Title from "../components/Titles/Titles.js";
import "../css/teams.css";
import TeamsComp from '../components/Teams/teams.js'; 
import { Helmet } from 'react-helmet';


export default function Teams() {
  return (
    <div>
        <Helmet>
        <title>VATSIM Spain | TEAMS</title>
      </Helmet>
      <Title name="TEAMS" />
      <div className="teamsFull">
        <TeamsComp />
    </div>
    </div>
  );
}
