import React from "react";
import "./PasosATC.css";
import { useTranslation } from "react-i18next";

const BeATC = () => {
  const [t, i18next] = useTranslation("global");
  return (
    <div className="ParentBe">
      <div className="child1Be">
        <h1>{t("be.slogan")}</h1>
        <ol>
          <li>
            <span>1.</span>
            {t("be.text1")} <br />{" "}
          </li>
          <li>
            <span>2.</span>
            {t("be.text2")}
          </li>
          <li>
            <span>3.</span>
            {t("be.text3")}
          </li>
          <li>
            <span>4.</span>
            {t("be.text4")}
          </li>
          <li className="line">
            <span>5.</span>
            {t("be.text5")}{" "}
            <a href="https://dashboard.vatsimspain.es/home">
              ‎ Dashboard
            </a>{" "}
            {t("be.text6")}
          </li>

          <h3>{t("be.bienvenido")}</h3>
        </ol>
        <p>{t("be.duda")}</p>
        <ul>
          <li>
            <a href="https://biblioteca.vatsimspain.es/books/manual-de-formacion/page/manual-de-formacion">
              {t("be.manual")}
            </a>
          </li>
          <li>
            <a href="https://my.vatsim.net/home">{t("be.perfil")}</a>
          </li>
          <li>
            <a href="https://vatsim.net/docs/">{t("be.primeros")}</a>
          </li>
        </ul>
      </div>
      <div className="child2Be">
        <iframe
          className="iframe-round"
          src="https://www.youtube.com/embed/KwQKDK2rUSU"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe>
      </div>
    </div>
  );
};

export default BeATC;
