// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../css/var.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-dropdown {
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    border-radius: 4px;
    padding: 4px 8px;
    min-width: 90px;
    position: relative;
  }
  
  .language-dropdown img {
    width: 24px; 
    height: 24px;
    margin-right: 10px;
    margin-top: -0px;
  }
  
  .language-dropdown::after {
    content: '▼';
    color: var(--text-primary);
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    pointer-events: none;
  }
  
  .language-dropdown select {
    appearance: none;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    flex: 1 1;
    z-index: 1;
    color: var(--text-primary);
  }


  option { 
    background-color: var(--text-invert);
}
  

  .language-dropdown select option:checked {
    font-weight: bold;
    color: var(--text-primary);
  }
  
  select{
    font-family: 'Poppins', sans-serif;
  }`, "",{"version":3,"sources":["webpack://./src/components/ButtonLanguage/buttonLanguage.css"],"names":[],"mappings":"AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,kCAAkC;IAClC,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,0BAA0B;IAC1B,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,2BAA2B;IAC3B,oBAAoB;EACtB;;EAEA;IACE,gBAAgB;IAChB,6BAA6B;IAC7B,YAAY;IACZ,aAAa;IACb,UAAU;IACV,eAAe;IACf,eAAe;IACf,SAAO;IACP,UAAU;IACV,0BAA0B;EAC5B;;;EAGA;IACE,oCAAoC;AACxC;;;EAGE;IACE,iBAAiB;IACjB,0BAA0B;EAC5B;;EAEA;IACE,kCAAkC;EACpC","sourcesContent":["@import url(../../css/var.css);\n@import url(\"https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap\");\n\n.language-dropdown {\n    display: flex;\n    align-items: center;\n    font-family: 'Poppins', sans-serif;\n    border-radius: 4px;\n    padding: 4px 8px;\n    min-width: 90px;\n    position: relative;\n  }\n  \n  .language-dropdown img {\n    width: 24px; \n    height: 24px;\n    margin-right: 10px;\n    margin-top: -0px;\n  }\n  \n  .language-dropdown::after {\n    content: '▼';\n    color: var(--text-primary);\n    position: absolute;\n    top: 50%;\n    right: 8px;\n    transform: translateY(-50%);\n    pointer-events: none;\n  }\n  \n  .language-dropdown select {\n    appearance: none;\n    background-color: transparent;\n    border: none;\n    outline: none;\n    padding: 0;\n    font-size: 16px;\n    cursor: pointer;\n    flex: 1;\n    z-index: 1;\n    color: var(--text-primary);\n  }\n\n\n  option { \n    background-color: var(--text-invert);\n}\n  \n\n  .language-dropdown select option:checked {\n    font-weight: bold;\n    color: var(--text-primary);\n  }\n  \n  select{\n    font-family: 'Poppins', sans-serif;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
