// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notFound{
text-align: center;
}
.textError{
    font-size: 200px;
    font-family: 'Poppins', sans-serif;
}

.surError{
    font-size: 23px;
    margin-left: 5vh;
    margin-right: 5vh;
}`, "",{"version":3,"sources":["webpack://./src/css/notFound.css"],"names":[],"mappings":"AAAA;AACA,kBAAkB;AAClB;AACA;IACI,gBAAgB;IAChB,kCAAkC;AACtC;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".notFound{\ntext-align: center;\n}\n.textError{\n    font-size: 200px;\n    font-family: 'Poppins', sans-serif;\n}\n\n.surError{\n    font-size: 23px;\n    margin-left: 5vh;\n    margin-right: 5vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
