import React, { useState, useEffect, useRef } from 'react';
import "./AboutUs.css";
import SocialMediaIcon from "../SocialMediaIcon/socialMediaIcon.js";
import { useTranslation } from "react-i18next";
import IG from "../../img/rrss/IG.png";
import YT from "../../img/rrss/YT.png";
import TW from "../../img/rrss/TW.png";
import DS from "../../img/rrss/DS.png";
import ReactGlobe from "react-globe.gl";

function Who(props) {
  const [t, i18next] = useTranslation("global");
  const [countries, setCountries] = useState({ features: []});

  const globeEl = useRef();

  useEffect(() => {
    globeEl.current.controls().enableZoom = false;
    globeEl.current.controls().autoRotate = true;
    globeEl.current.controls().autoRotateSpeed = 0.5;
  }, []);

  const markerSvg = `<svg viewBox="-4 0 36 36">
    <path fill="currentColor" d="M14,0 C21.732,0 28,5.641 28,12.6 C28,23.963 14,36 14,36 C14,36 0,24.064 0,12.6 C0,5.641 6.268,0 14,0 Z"></path>
    <circle fill="black" cx="14" cy="14" r="7"></circle>
  </svg>`;
  
  const N = 1;

  const gData = [...Array(N).keys()].map(() => ({
    lat: 40.409895,
    lng: -3.709368,
    size: 30,
    color: '#A30000'
  }));


  return (
    <div className="containerWho" id="who">
      <div className="globeContainer">
      <ReactGlobe
      ref={globeEl}
      globeImageUrl="//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
      htmlElementsData={gData}
      backgroundColor='rgba(0,0,0,0)'
      htmlElement={d => {
        const el = document.createElement('div');
        el.innerHTML = markerSvg;
        el.style.color = d.color;
        el.style.width = `${d.size}px`;
        el.style['pointer-events'] = 'auto';
        el.style.cursor = 'pointer';
        el.onclick = () => console.info(d);
        return el;
      }}
      height={600}
      width={600}
      animateIn={true}
      enablePointerInteraction={false}
      pointOfView={[40.378634, -3.721706,2.5]}
      enableZoom={false}
      controls
    />
      </div>
        <div className="vatLogoContainer">
          <div className="vatLogo"></div>
          <h3 className="presentationTexth4">{t("who.who1")}</h3>
          <h4 className="presentationTexth5">{t("who.who2")}</h4>
          <h4 className="presentationTexth5">{t("who.who3")}</h4>
          <div className="socialIcons">
            <SocialMediaIcon
              location={IG}
              href="https://www.instagram.com/vatsimspain/"
            />
            <SocialMediaIcon
              location={TW}
              href="https://twitter.com/vatsimSpain"
            />
            <SocialMediaIcon
              location={YT}
              href="https://www.youtube.com/@vatsimspain9466"
            />
            <SocialMediaIcon
              location={DS}
              href="https://dashboard.vatsimspain.es/home/perfil"
            />
          </div>
        </div>
      </div>
  );
}

export default Who;