import React from 'react';
import './loading.css'

const items = Array('MAN, FLEX 62, SRS, RWY, AUTO THRUST BLUE', 'FLAPS FULL','ECAM MEMO, LANDING NO BLUE', 'GEAR DOWN, CHECK','CONTINUE', 'SPOILERS, REVERSE GREEN, DECEL', 'RADAR CONTACT', 'MONITOR UNICOM', 'GOOD FLIGHT!','MAYDAY, MAYDAY, MAYDAY', 'PULL UP! TERRAIN! PULL UP!','NEVER GONNA GIVE YOU UP!','AVIATE, NAVIGATE, COMMUNICATE','SKY IS HOME','STARTING SIMULATOR','DESCENT FLIGHT LEVEL 180', 'CLEARED TO LAND','TAXI TO HOLDING POINT H2 VIA L AND JD','LINE UP AND WAIT RUNWAY 36R', 'REDUCE SPEED 220 KNOTS' );
const random = Math.floor(Math.random() * items.length);

const Loading = () => {
  return (
    <div>
    <div className="loading-overlay">
      <div className="loading-spinner"> </div>
    </div>
    <div className='text' >{'\n'}{items[random] + '...'}</div>
    </div>
  );
};

export default Loading;