import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

type Theme = 'dark' | 'light';
type useThemeReturn = [Theme, () => void];

export const useTheme = (initialTheme: Theme): useThemeReturn => {
  const [theme, setTheme] = useState<Theme>(() => {
    // Check if there is a saved theme in the cookie
    const savedTheme = Cookies.get('selectedTheme');
    return savedTheme && ['dark', 'light'].includes(savedTheme) ? (savedTheme as Theme) : initialTheme;
  });

  const toggleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark'; // Toggle between 'dark' and 'light'
    setTheme(newTheme);
    Cookies.set('selectedTheme', newTheme, { expires: 365 }); // Set cookie for one year
  };

  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
  }, [theme]);

  return [theme, toggleTheme];
};
