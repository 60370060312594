// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../css/var.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-overlay {
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--background);
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .loading-spinner{
    animation: 1.5s fade;
    text-align: center;
    background-image: var(--imagemode);
    background-repeat: no-repeat;
    background-size: 250px;
    height: 140px;
    width: 250px;
  }

  .text{
    font-style: italic;
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: var(--text-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
    animation: 1.5s fade;
    font-size: 20px;
    text-align: center;
  }
  @keyframes fade {
    0%,100% { opacity: 0 }
    50% { opacity: 1 }
  }`, "",{"version":3,"sources":["webpack://./src/components/Loading/loading.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,mCAAmC;IACnC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;EAErB;;EAEA;IACE,oBAAoB;IACpB,kBAAkB;IAClB,kCAAkC;IAClC,4BAA4B;IAC5B,sBAAsB;IACtB,aAAa;IACb,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,aAAa;IACb,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,0BAA0B;IAC1B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,oBAAoB;IACpB,eAAe;IACf,kBAAkB;EACpB;EACA;IACE,UAAU,WAAW;IACrB,MAAM,WAAW;EACnB","sourcesContent":["@import url(../../css/var.css);\n\n.loading-overlay {\n    z-index: 2000;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: var(--background);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    \n  }\n  \n  .loading-spinner{\n    animation: 1.5s fade;\n    text-align: center;\n    background-image: var(--imagemode);\n    background-repeat: no-repeat;\n    background-size: 250px;\n    height: 140px;\n    width: 250px;\n  }\n\n  .text{\n    font-style: italic;\n    z-index: 2000;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    color: var(--text-primary);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-top: 10vh;\n    animation: 1.5s fade;\n    font-size: 20px;\n    text-align: center;\n  }\n  @keyframes fade {\n    0%,100% { opacity: 0 }\n    50% { opacity: 1 }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
