import React, { useState, useEffect } from 'react';
import './ATC.css';
import { useTranslation } from 'react-i18next';
import Button1 from '../Button/button1'
import LoadingComponent from '../LoadingComponent/LoadingComponent.js'

export default function VatsimControllerList() {
  const [controllers, setControllers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation("global");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://dashboard.vatsimspain.es/api/controladoresConectadosOnline",
          {
            method: "GET",
            headers: {
              "X-API-Key": process.env.REACT_APP_API_KEY,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        setControllers(data);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className='contenedor'>
      <h1><i className="fa-solid fa-tower-cell"></i> {t("ATC.title")}</h1>
      <div id="controllerList">
        {loading ? (
          <LoadingComponent />
        ) : controllers.length > 0 ? (
          <table className='table1'>
            <tbody>
              {controllers.map((controller, index) => (
                <tr key={index}>
                  <td className='hoverText' title={controller.nombre_controlador}>{controller.controlador_posicion}</td>
                  <td className='hoverText' title={t("ATC.conn") + controller.hora_conectado}>{controller.controlador_frecuencia}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>{t("ATC.noActive")}</p>
        )}
      </div>
      <br/>
      <div className='alignCenter'>
      <Button1 text={t("who.controllers")} href="/controladores" type="_self"/>
      </div>
    </div>
  );
}