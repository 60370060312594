import React, { useEffect, useState } from "react";
import "../css/staff.css";
import Title from "../components/Titles/Titles.js";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import LoadingComponent from "../components/LoadingComponent/LoadingComponent.js";
import { Helmet } from 'react-helmet';

const ExaminadoresTab = () => {
  const { t } = useTranslation('global');
  const [examinadores, setExaminadores] = useState([]);
  const [loading, setLoading] = useState(true);
  const savedLanguage = i18next.language;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://dashboard.vatsimspain.es/api/staff/examinadores",
          {
            method: "GET",
            headers: {
              "X-API-Key": process.env.REACT_APP_API_KEY,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        setExaminadores(data);
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>VATSIM Spain | {t("navbar.examiners1")}</title>
      </Helmet>
        <div>
          <Title name={t("navbar.examiners1")} />
          <div className="containerStaff">
          {loading ? (
            <LoadingComponent />
          ) : (
            <div className="boxStaff">
              {examinadores.map((examinador) => (
                <div key={examinador.usuario_id} className="staffBox">
                  <div key={examinador.usuario_id}>
                    <a className="staffName">{examinador.nombre}</a>
                    <a className="staffEmail" href={`mailto:${examinador.email}`}>
                      {examinador.email}
                    </a>
                    <br/>
                  </div>
                </div>
              ))}
            </div>
          )}
          </div>
        </div>
    </div>
  );
};

export default ExaminadoresTab;
