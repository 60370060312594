import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import LoadingModule from '../Loading/LoadingModule.js'

export default function LoadingScreen() {

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const [isLoading, setIsLoading] = useState(true);


  return (
    <CSSTransition
    in={isLoading}
    timeout={500}
    classNames="fade"
    unmountOnExit
  >
    <LoadingModule/>
  </CSSTransition>
  )
}
