// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }
  
  .fade-exit-done {
    opacity: 0;
  }`, "",{"version":3,"sources":["webpack://./src/css/fade.css"],"names":[],"mappings":";AACA;IACI,UAAU;EACZ;;EAEA;IACE,UAAU;IACV,iCAAiC;EACnC;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;IACV,iCAAiC;EACnC;;EAEA;IACE,UAAU;EACZ","sourcesContent":["\n.fade-enter {\n    opacity: 0;\n  }\n  \n  .fade-enter-active {\n    opacity: 1;\n    transition: opacity 500ms ease-in;\n  }\n  \n  .fade-exit {\n    opacity: 1;\n  }\n  \n  .fade-exit-active {\n    opacity: 0;\n    transition: opacity 500ms ease-in;\n  }\n  \n  .fade-exit-done {\n    opacity: 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
