import React, { useState, useEffect } from 'react';
import './slides.css';
import Button2 from '../Button/button2.js';
import { useTranslation } from 'react-i18next';
import BackgroundVideo from '../../img/background.mp4';
import Typewriter from 'react-ts-typewriter';

function Slides(props) {
  const [t, i18next] = useTranslation("global");
  const [typewriterVisible, setTypewriterVisible] = useState(false);

  useEffect(() => {
    // Delay the execution of typewriterVisible by 2 seconds (2000 milliseconds)
    const timer = setTimeout(() => {
      setTypewriterVisible(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>      <div className="shapedividers_com-8447"></div>
    <div className='slides'>
      <video autoPlay muted loop>
        <source src={BackgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className='divTitle'>
        {typewriterVisible && (
          <div>
            <h1 className='title'>
              <Typewriter text={t("slides.title")} speed={100} />
            </h1>
            <h3 className='title2'>
              <Typewriter text={t("slides.slogan")} speed={100} />
            </h3>
            <Button2 text={t("slides.button")} anchor='who' />
          </div>
        )}
      </div>
      </div>
    </div>
  );
}

export default Slides;
