import React from 'react';
import { useTranslation } from 'react-i18next'
import './teams.css';
import aea from '../../img/va/aea.png'
import exc from '../../img/va/exc.png'
import mdt from '../../img/va/mdt.png'
import thr from '../../img/va/thr.png'
import vlg from '../../img/va/vlg.png'
import vateud from '../../img/patrocinadores/vateud.png'
import vatsim from '../../img/patrocinadores/vatsim.png'
import apcav from '../../img/patrocinadores/apcav.png'



export default function Teams() {
  const [t, i18next] = useTranslation("global");

  return (
    <div className='full-container'>
        <h3>{t("teams.text")}</h3>
    <div className="teams-container">
      <a href='https://aireuropavirtual.com/' target="_blank"><img src={aea} alt="Image 1" className="team-image" /></a>
      <a href='https://executive-airlinesva.es' target="_blank"><img src={exc} alt="Image 3" className="team-image" /></a>
      <a href='https://home.mediteavirtual.es/' target="_blank"><img src={mdt} alt="Image 4" className="team-image" /></a>
      <a href='https://thorairlines.com/' target="_blank" ><img src={thr} alt="Image 5" className="team-image" /></a>
      <a href='https://virtualvueling.eu/wp2/' target="_blank" ><img src={vlg} alt="Image 6" className="team-image" /></a>
    </div>
    <div className="teams-container">
      <a href='https://vateud.net/' target="_blank"><img src={vateud} alt="Image 7" className="pat-image" /></a>
      <a href='https://vatsim.net/' target="_blank"><img src={vatsim} alt="Image 8" className="pat-image" /></a>
      <a href='https://apcav.org/' target="_blank"><img src={apcav} alt="Image 9" className="pat-image" /></a>
    </div>
    </div>
  );
}